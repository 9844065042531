import { ETokenSymbol } from "common/enums/currency";
import React from "react";

import { ReactComponent as AdaIcon } from "assets/images/icons/currencies/ada.svg";
import { ReactComponent as AvaxIcon } from "assets/images/icons/currencies/avax.svg";
import { ReactComponent as BnbIcon } from "assets/images/icons/currencies/bnb.svg";
import { ReactComponent as BtcIcon } from "assets/images/icons/currencies/btc.svg";
import { ReactComponent as EthIcon } from "assets/images/icons/currencies/eth.svg";
import { ReactComponent as MaticIcon } from "assets/images/icons/currencies/matic.svg";
import { ReactComponent as SolIcon } from "assets/images/icons/currencies/sol.svg";
import { ReactComponent as XtzIcon } from "assets/images/icons/currencies/tz.svg";
import { ReactComponent as UsdcIcon } from "assets/images/icons/currencies/usdc.svg";
import { ReactComponent as WethIcon } from "assets/images/icons/currencies/weth.svg";

type IProps = {
	symbol: ETokenSymbol;
};

export default class MockedTokenIcon extends React.Component<IProps> {
	public override render(): JSX.Element {
		return <>{this.getTokenIcon(this.props.symbol)}</>;
	}

	private getTokenIcon(tokenSymbol: ETokenSymbol) {
		switch (tokenSymbol) {
			case ETokenSymbol.ADA:
				return <AdaIcon />;
			case ETokenSymbol.AVAX:
				return <AvaxIcon />;
			case ETokenSymbol.BNB:
				return <BnbIcon />;
			case ETokenSymbol.BTC:
				return <BtcIcon />;
			case ETokenSymbol.ETH:
				return <EthIcon />;
			case ETokenSymbol.POL:
				return <MaticIcon />;
			case ETokenSymbol.SOL:
				return <SolIcon />;
			case ETokenSymbol.XTZ:
				return <XtzIcon />;
			case ETokenSymbol.USDC:
				return <UsdcIcon />;
			case ETokenSymbol.WETH:
				return <WethIcon />;
			default:
				return null;
		}
	}
}
