import { ETokenSymbol } from "common/enums/currency";
import { ethers } from "ethers";
import BigNumber from "Services/BigNumber";
import TokenSupports from "Stores/TokenSupports";
import assert from "assert";
import { AbiType } from "common/enums/AbiType";
import { ContractType } from "Entities/appContract";

export type IFunctionName = "balanceOf" | "allowance" | "approve" | "transfer";

type PopulatedTransactionSupportedValueAsString = Omit<ethers.PopulatedTransaction, "value"> & {
	value?: ethers.PopulatedTransaction["value"] | string;
};

export default abstract class BaseContract {
	protected contract: ethers.Contract;
	private _address: string;
	private _config: Record<string, unknown> | null;
	private _abiType: AbiType;
	protected _contractType: ContractType;

	public constructor(params: {
		address: string;
		config?: Record<string, unknown>;
		ethersContract: ethers.Contract;
		abiType: AbiType;
		contractType: ContractType;
	}) {
		this.contract = params.ethersContract;
		this._config = params.config ?? null;
		this._address = params.address;
		this._abiType = params.abiType;
		this._contractType = params.contractType;
	}

	public async estimateGasPrice(tx: PopulatedTransactionSupportedValueAsString): Promise<BigNumber> {
		// TODO check why the following fetch and assert
		const tokenNative = TokenSupports.getInstance().getTokenByName(ETokenSymbol.POL);
		assert(tokenNative, "POL Token not found");

		assert(this.contract, `Contract has not been initialized for address ${this._address}`);

		const gasLimit = await this.contract.provider.estimateGas(tx);
		const maxFeePerGas = (await this.contract.provider.getFeeData())?.maxPriorityFeePerGas;
		assert(maxFeePerGas, "Max fee per gas not found");

		return BigNumber.from(ethers.utils.formatEther(maxFeePerGas.mul(gasLimit)), tokenNative.decimals);
	}

	public is<T extends BaseContract>(ctor: new (...args: any[]) => T): this is T {
		return this instanceof ctor;
	}

	public get address(): string {
		return this._address;
	}

	protected get config(): Record<string, unknown> | null {
		return this._config;
	}

	public get abiType(): AbiType {
		return this._abiType;
	}

	public get contractType(): ContractType {
		return this._contractType;
	}
}
