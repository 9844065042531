import React, { lazy, Suspense, useEffect } from "react";
import { Navigate, Outlet, Route, BrowserRouter as Router, Routes, useLocation } from "react-router-dom";

import { RuleActions, RuleNames } from "Entities/appRule";
import StoreWorkflow from "Services/StoreWorkflow";
import RouterLocation from "Stores/RouterLocation";

// Stores
import IsModuleEnabled from "./Elements/IsModuleEnabled";
import Rules, { RulesMode } from "./Materials/Rules";
import ModuleStore from "Stores/ModuleStore";
import ConfigStore from "Stores/ConfigStore";

import { EAppConfigHomepage } from "Entities/appConfig";
import { ConfigValidator } from "common/helpers/configValidator";
import LandingPageTemplate from "./PageTemplates/LandingPageTemplate";

// Components
const Launchpad = React.lazy(() => import("./Pages/Launchpad"));
const Collection = React.lazy(() => import("./Pages/Collection"));
const CollectionActivities = React.lazy(() => import("./Pages/Collection/Activities"));
const CollectionProjectInfo = React.lazy(() => import("./Pages/Collection/ProjectInfo"));
const CollectionNFTs = React.lazy(() => import("./Pages/Collection/NFTs"));
const Components = React.lazy(() => import("./Pages/Components"));
const Outlet1 = React.lazy(() => import("./Pages/Components/Tabs/Outlet1"));
const Outlet2 = React.lazy(() => import("./Pages/Components/Tabs/Outlet2"));
const Home = React.lazy(() => import("./Pages/Home"));
const Launches = React.lazy(() => import("./Pages/Launches"));
const Market = React.lazy(() => import("./Pages/Market"));
const All = React.lazy(() => import("./Pages/Market/All"));
const Collections = React.lazy(() => import("./Pages/Market/Collections"));
const NFTs = React.lazy(() => import("./Pages/Market/NFTs"));
const NFT = React.lazy(() => import("./Pages/NFT"));
const Profile = React.lazy(() => import("./Pages/Profile"));
const Activities = React.lazy(() => import("./Pages/Profile/Activities"));
const History = React.lazy(() => import("./Pages/Profile/Activities/History"));
const ListingsAuctions = React.lazy(() => import("./Pages/Profile/Activities/MyListings/ListingsAuctions"));
const ListingsFixedPrice = React.lazy(() => import("./Pages/Profile/Activities/MyListings/ListingsFixedPrice"));
const Offers = React.lazy(() => import("./Pages/Profile/Activities/MyOrders/Offers"));
const OrdersAuctions = React.lazy(() => import("./Pages/Profile/Activities/MyOrders/OrdersAuctions"));
const MyNFTs = React.lazy(() => import("./Pages/Profile/MyNFTs"));
const Settings = React.lazy(() => import("./Pages/Settings"));
const General = React.lazy(() => import("./Pages/Settings/General"));
const SocialMedia = React.lazy(() => import("./Pages/Settings/SocialMedia"));
const Transfer = React.lazy(() => import("./Pages/Transfer"));
const LaunchesCollection = React.lazy(() => import("./Pages/Launches/LaunchesCollection"));
const NotFound = React.lazy(() => import("./Pages/Errors/NotFound"));
const Forbidden = React.lazy(() => import("./Pages/Errors/Forbidden"));
const ClaimItem = React.lazy(() => import("./Pages/ClaimItem"));
const Deliveries = React.lazy(() => import("./Pages/Deliveries"));

type IProps = Record<string, never>;
type IState = {
	homepage: EAppConfigHomepage;
};

export default class MainRouter extends React.Component<IProps, IState> {
	public constructor(props: IProps) {
		super(props);

		const homepage = ConfigValidator.validateHomepage(ConfigStore.getInstance().config.homepage);
		this.state = {
			homepage: homepage ?? EAppConfigHomepage.HOME,
		};
	}

	public override render(): JSX.Element {
		const pageConfig = IsModuleEnabled.get().pages;
		return (
			<Router basename="/">
				<Suspense fallback={<div>Loading...</div>}>
					<Routes>
						<Route element={<BindRouter />}>
							<Route element={<Navigate to={pageConfig.Home.props.path.pathname} replace />} path="*" />
							{this.getHomepageRoute()}

							<Route
								element={
									<IsModuleEnabled from={pageConfig.Launchpad} isPage>
										<Launchpad />
									</IsModuleEnabled>
								}
								path={pageConfig.Launchpad.props.path}
							/>

							<Route
								element={
									<IsModuleEnabled from={pageConfig.Launches} isPage>
										<Launches />
									</IsModuleEnabled>
								}
								path={pageConfig.Launches.props.path}>
								<Route path="" element={<Navigate to={pageConfig.Launches.props.pages.Drops.props.path} />} />
								<Route
									path={pageConfig.Launches.props.pages.Drops.props.path}
									element={
										<IsModuleEnabled from={pageConfig.Launches.props.pages.Drops} isPage>
											<LaunchesCollection />
										</IsModuleEnabled>
									}
								/>
								<Route
									path={pageConfig.Launches.props.pages.Collects.props.path}
									element={
										<IsModuleEnabled from={pageConfig.Launches.props.pages.Collects} isPage>
											<LaunchesCollection />
										</IsModuleEnabled>
									}
								/>
							</Route>

							<Route
								element={
									<IsModuleEnabled from={pageConfig.Market} isPage>
										<Market />
									</IsModuleEnabled>
								}
								path={pageConfig.Market.props.path}>
								<Route path="" element={<Navigate to={pageConfig.Market.props.pages.All.props.path} />} />
								<Route
									path={pageConfig.Market.props.pages.All.props.path}
									element={
										<IsModuleEnabled from={pageConfig.Market.props.pages.All} isPage>
											<All />
										</IsModuleEnabled>
									}
								/>
								<Route
									path={pageConfig.Market.props.pages.Collections.props.path}
									element={
										<IsModuleEnabled from={pageConfig.Market.props.pages.Collections} isPage>
											<Collections />
										</IsModuleEnabled>
									}
								/>
								<Route
									path={pageConfig.Market.props.pages.NFTs.props.path}
									element={
										<IsModuleEnabled from={pageConfig.Market.props.pages.NFTs} isPage>
											<NFTs />
										</IsModuleEnabled>
									}
								/>
							</Route>
							<Route
								element={
									<IsModuleEnabled from={pageConfig.Profile} isPage>
										<Profile />
									</IsModuleEnabled>
								}
								path={pageConfig.Profile.props.path}>
								<Route path="" element={<Navigate to={pageConfig.Profile.props.pages.Nfts.props.path} />} />
								<Route
									path={pageConfig.Profile.props.pages.Nfts.props.path}
									element={
										<IsModuleEnabled from={pageConfig.Profile.props.pages.Nfts} isPage>
											<MyNFTs />
										</IsModuleEnabled>
									}
								/>
								<Route
									path={pageConfig.Profile.props.pages.Activities.props.path}
									element={
										<IsModuleEnabled from={pageConfig.Profile.props.pages.Activities} isPage>
											<Activities />
										</IsModuleEnabled>
									}>
									<Route
										path={pageConfig.Profile.props.pages.Activities.props.Menu.options.Orders.props.Offers.props.path}
										element={
											<IsModuleEnabled
												from={pageConfig.Profile.props.pages.Activities.props.Menu.options.Orders.props.Offers}
												isPage>
												<Offers />
											</IsModuleEnabled>
										}
									/>

									<Route
										path={pageConfig.Profile.props.pages.Activities.props.Menu.options.Orders.props.Auctions.props.path}
										element={
											<IsModuleEnabled
												from={pageConfig.Profile.props.pages.Activities.props.Menu.options.Orders.props.Auctions}
												isPage>
												<OrdersAuctions />
											</IsModuleEnabled>
										}
									/>

									<Route
										path={
											pageConfig.Profile.props.pages.Activities.props.Menu.options.Listings.props.FixedPrice.props
												.path
										}
										element={
											<IsModuleEnabled
												from={
													pageConfig.Profile.props.pages.Activities.props.Menu.options.Listings.props.FixedPrice
												}
												isPage>
												<ListingsFixedPrice />
											</IsModuleEnabled>
										}
									/>

									<Route
										path={
											pageConfig.Profile.props.pages.Activities.props.Menu.options.Listings.props.Auctions.props.path
										}
										element={
											<IsModuleEnabled
												from={pageConfig.Profile.props.pages.Activities.props.Menu.options.Listings.props.Auctions}
												isPage>
												<ListingsAuctions />
											</IsModuleEnabled>
										}
									/>

									<Route
										path={pageConfig.Profile.props.pages.Activities.props.Menu.options.Other.props.History.props.path}
										element={
											<IsModuleEnabled
												from={pageConfig.Profile.props.pages.Activities.props.Menu.options.Other.props.History}
												isPage>
												<History />
											</IsModuleEnabled>
										}
									/>

									<Route
										path=""
										element={
											<Navigate
												to={
													pageConfig.Profile.props.pages.Activities.props.Menu.options.Other.props.History.props
														.path
												}
											/>
										}
									/>
								</Route>
							</Route>
							<Route
								element={
									<IsModuleEnabled from={pageConfig.Settings} isPage>
										<Rules
											isPage
											mode={RulesMode.NECESSARY}
											rules={[
												{
													name: RuleNames.USERS,
													action: RuleActions.UPDATE_SELF,
												},
											]}>
											<Settings />
										</Rules>
									</IsModuleEnabled>
								}
								path={pageConfig.Settings.props.path}>
								<Route path="" element={<Navigate to={pageConfig.Settings.props.pages.General.props.path} />} />
								<Route path={pageConfig.Settings.props.pages.General.props.path} element={<General />} />
								<Route path={pageConfig.Settings.props.pages.SocialMedia.props.path} element={<SocialMedia />} />
							</Route>
							<Route
								element={
									<IsModuleEnabled from={pageConfig.Components} isPage>
										<Components />
									</IsModuleEnabled>
								}
								path={pageConfig.Components.props.path}
							/>
							<Route
								element={
									<IsModuleEnabled from={pageConfig.Collection} isPage>
										<Collection />
									</IsModuleEnabled>
								}
								path={pageConfig.Collection.props.path}>
								<Route path="" element={<Navigate to={pageConfig.Collection.props.pages.ProjectInfo.props.path} />} />
								<Route path={pageConfig.Collection.props.pages.Nfts.props.path} element={<CollectionNFTs />} />
								<Route path={pageConfig.Collection.props.pages.Activities.props.path} element={<CollectionActivities />} />
								<Route
									path={pageConfig.Collection.props.pages.ProjectInfo.props.path}
									element={<CollectionProjectInfo />}
								/>
							</Route>
							<Route
								element={
									<IsModuleEnabled from={pageConfig.NFT} isPage>
										<NFT />
									</IsModuleEnabled>
								}
								path={pageConfig.NFT.props.path}
							/>
							<Route
								element={
									<IsModuleEnabled from={pageConfig.Transfer} isPage>
										<Transfer />
									</IsModuleEnabled>
								}
								path={pageConfig.Transfer.props.path}
							/>
							<Route
								element={
									<IsModuleEnabled from={pageConfig.NotFound} isPage>
										<NotFound />
									</IsModuleEnabled>
								}
								path={pageConfig.NotFound.props.path}
							/>
							<Route
								element={
									<IsModuleEnabled from={pageConfig.Forbidden} isPage>
										<Forbidden />
									</IsModuleEnabled>
								}
								path={pageConfig.Forbidden.props.path}
							/>
							<Route
								element={
									<IsModuleEnabled from={pageConfig.ClaimItem} isPage>
										<Rules
											isPage
											mode={RulesMode.NECESSARY}
											rules={[
												{
													name: RuleNames.REDEEM_ASSETS,
													action: RuleActions.CREATE,
												},
											]}
											redirectTo={pageConfig.Market.props.path}>
											<ClaimItem />
										</Rules>
									</IsModuleEnabled>
								}
								path={pageConfig.ClaimItem.props.path}
							/>
							<Route
								element={
									<IsModuleEnabled from={pageConfig.Deliveries} isPage>
										<Rules
											isPage
											mode={RulesMode.NECESSARY}
											rules={[
												{
													name: RuleNames.REDEEM_ASSETS,
													action: RuleActions.READ,
												},
											]}
											redirectTo={pageConfig.Market.props.path}>
											<Deliveries />
										</Rules>
									</IsModuleEnabled>
								}
								path={pageConfig.Deliveries.props.path}
							/>
							{this.ComponentsRoutes()}
						</Route>
					</Routes>
				</Suspense>
			</Router>
		);
	}

	public override async componentDidMount() {
		ConfigStore.getInstance().onChange((config) => {
			const homepage = ConfigValidator.validateHomepage(config.homepage);
			this.setState({ homepage: homepage ?? EAppConfigHomepage.HOME });
		});
	}

	private getHomepageRoute() {
		const pageConfig = IsModuleEnabled.get().pages;
		return (
			<Route element={this.getHomepageModule()} path={pageConfig.Home.props.path.pathname}>
				{this.getHomepageSubRoutes()}
			</Route>
		);
	}

	private getHomepageModule() {
		const pageConfig = IsModuleEnabled.get().pages;
		switch (this.state.homepage) {
			case EAppConfigHomepage.HOME:
				const landingPagesModule = ModuleStore.getInstance().module.modules.LandingPages;
				return (
					<IsModuleEnabled from={landingPagesModule} isPage>
						<Suspense fallback={<div>Loading...</div>}>
							<Home />
						</Suspense>
					</IsModuleEnabled>
				);
			case EAppConfigHomepage.MARKET:
				return (
					<IsModuleEnabled from={pageConfig.Market} isPage>
						<Market />
					</IsModuleEnabled>
				);
			case EAppConfigHomepage.LAUNCHES:
				return (
					<IsModuleEnabled from={pageConfig.Launchpad} isPage>
						<Launchpad />
					</IsModuleEnabled>
				);
			default:
				return (
					<IsModuleEnabled from={pageConfig.Home} isPage>
						<Home />
					</IsModuleEnabled>
				);
		}
	}

	private getHomepageSubRoutes() {
		const pageConfig = IsModuleEnabled.get().pages;

		switch (this.state.homepage) {
			case EAppConfigHomepage.HOME:
				const landingPagesModule = ModuleStore.getInstance().module.modules.LandingPages;

				return (
					<>
						{landingPagesModule.pages.map((page) => (
							<Route
								key={page.path}
								path={page.path}
								element={<LandingPageTemplate components={page.components} props={page.props} />}
							/>
						))}
					</>
				);
			case EAppConfigHomepage.LAUNCHES:
				return (
					<>
						<Route path="" element={<Navigate to={pageConfig.Launches.props.pages.Drops.props.path} />} />
						<Route
							path={pageConfig.Launches.props.pages.Drops.props.path}
							element={
								<IsModuleEnabled from={pageConfig.Launches.props.pages.Drops} isPage>
									<LaunchesCollection />
								</IsModuleEnabled>
							}
						/>
						<Route
							path={pageConfig.Launches.props.pages.Collects.props.path}
							element={
								<IsModuleEnabled from={pageConfig.Launches.props.pages.Collects} isPage>
									<LaunchesCollection />
								</IsModuleEnabled>
							}
						/>
					</>
				);
			case EAppConfigHomepage.MARKET:
				return (
					<>
						<Route path="" element={<Navigate to={pageConfig.Market.props.pages.All.props.path} />} />
						<Route
							path={pageConfig.Market.props.pages.All.props.path}
							element={
								<IsModuleEnabled from={pageConfig.Market.props.pages.All} isPage>
									<All />
								</IsModuleEnabled>
							}
						/>

						<Route
							path={pageConfig.Market.props.pages.Collections.props.path}
							element={
								<IsModuleEnabled from={pageConfig.Market.props.pages.Collections} isPage>
									<Collections />
								</IsModuleEnabled>
							}
						/>

						<Route
							path={pageConfig.Market.props.pages.NFTs.props.path}
							element={
								<IsModuleEnabled from={pageConfig.Market.props.pages.NFTs} isPage>
									<NFTs />
								</IsModuleEnabled>
							}
						/>
					</>
				);

			default:
				return <></>;
		}
	}

	private ComponentsRoutes() {
		const componentsPages = IsModuleEnabled.get().pages.Components.pages;
		return Object.entries(componentsPages).map(([key, value]) => {
			const Component = lazy(() => import("./Pages/Components/".concat(key)));
			if (key === "Tabs") {
				return (
					<Route
						element={
							<IsModuleEnabled from={value} isPage>
								<Component />
							</IsModuleEnabled>
						}
						path={value.props.path}
						key={key}>
						<Route path="outlet1" element={<Outlet1 />} />
						<Route path="outlet2" element={<Outlet2 />} />
					</Route>
				);
			}
			return (
				<Route
					element={
						<IsModuleEnabled from={value} isPage>
							<Component />
						</IsModuleEnabled>
					}
					path={value.props.path}
					key={key}
				/>
			);
		});
	}
}

function BindRouter() {
	const location = useLocation();

	RouterLocation.getInstance().preSet(location);
	useEffect(() => {
		RouterLocation.getInstance().change(location);
		StoreWorkflow.getInstance().closeOnTopLayouts();
		document.body.setAttribute("route", location.pathname);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	return <Outlet />;
}
