import classNames from "classnames";
import React from "react";

import TransactionToast from "common/helpers/TransactionToast";
import Button, { EButtonColor, EButtonVariant } from "Components/Elements/Buttons/Button";
import I18n from "Components/Elements/I18n";
import Modal from "Components/Elements/Modals";
import Typography from "Components/Elements/Typography";
import TokenPrice from "Components/Materials/TokenPrice";
import { AppUserEntity } from "Entities/appUser";
import UserStore from "Stores/UserStore";

import classes from "./classes.module.scss";
import AmountToSendModalStore from "Stores/Modals/AmountToSendModalStore";
import ConfirmPaymentModalStore from "Stores/Modals/ConfirmPaymentModalStore";
import { EModal } from "Stores/Modals/AModalStore";
import assert from "assert";
import { AppTokenSupportEntity } from "Entities/appTokenSupport";
import BigNumber from "Services/BigNumber";
import TokenSupports from "Stores/TokenSupports";
import { ETokenSymbol } from "common/enums/currency";
import WalletStore from "Stores/WalletStore";
import Erc20ContractStore from "Stores/ContractStores/Erc20ContractStore";
import { TokenProtocol } from "common/enums/TokenProcotol";

export type IConfirmPaymentModalProps = {
	amountToSend: BigNumber;
	receiverAddress: string;
	tokenSupportEntity: AppTokenSupportEntity;
};

type IProps = {};

type IState = {
	isOpen: boolean;
	paymentInfo?: IConfirmPaymentModalProps;
	user: AppUserEntity | null;
	loading: boolean;
	tx?: any;
	gasPrice?: BigNumber;
	nativeToken?: AppTokenSupportEntity;
};

export default class ConfirmPaymentModal extends React.Component<IProps, IState> {
	private removeOnModalChange = () => {};
	private removeOnUserChange = () => {};

	constructor(props: IProps) {
		super(props);
		this.state = {
			isOpen: ConfirmPaymentModalStore.getInstance().isOpen(EModal.CONFIRM_PAYMENT),
			paymentInfo: ConfirmPaymentModalStore.getInstance().getProps(),
			user: UserStore.getInstance().getUser(),
			loading: false,
		};

		this.updateModalState = this.updateModalState.bind(this);
		this.close = this.close.bind(this);
		this.openAmountToSendModal = this.openAmountToSendModal.bind(this);
		this.confirmPayment = this.confirmPayment.bind(this);
		this.updateUser = this.updateUser.bind(this);
		this.estimateGasPrice = this.estimateGasPrice.bind(this);
	}

	override render(): JSX.Element | null {
		const { paymentInfo } = this.state;
		return (
			<Modal isOpen={this.state.isOpen} header={paymentInfo?.tokenSupportEntity?.name} closeBtn onClose={this.close}>
				<div className={classes["root"]}>
					<div className={classes["confirmInfo"]}>
						<div className={classNames(classes["titles"], classes["column"])}>
							<Typography type="p" size="small" weight="semibold">
								{I18n.translate("modals.confirm.from")}
							</Typography>
							<Typography type="p" size="small" weight="semibold">
								{I18n.translate("modals.confirm.to")}
							</Typography>
							<Typography type="p" size="small" weight="semibold">
								{I18n.translate("modals.confirm.amount")}
							</Typography>
							<Typography type="p" size="small" weight="semibold">
								{I18n.translate("modals.confirm.fee")}
							</Typography>
						</div>
						<div className={classNames(classes["data"], classes["column"])}>
							<Typography type="p" size="small" weight="regular">
								{this.state.user?.appWallet?.userAddress}
							</Typography>
							<Typography type="p" size="small" weight="regular">
								{paymentInfo?.receiverAddress}
							</Typography>
							<TokenPrice
								price={paymentInfo?.amountToSend}
								token={paymentInfo?.tokenSupportEntity}
								typo={{ type: "p", size: "small", weight: "regular" }}
								conversionConfig={{ size: "small", direction: "row" }}
							/>
							<TokenPrice
								price={this.state.gasPrice}
								token={this.state.nativeToken}
								typo={{ type: "p", size: "small", weight: "regular" }}
								conversionConfig={{ size: "small", direction: "row" }}
							/>
						</div>
					</div>
					<div className={classes["total"]}>
						<Typography type="p" size="large" weight="bold">
							Total
						</Typography>
						<TokenPrice
							price={paymentInfo?.amountToSend}
							typo={{ type: "p", size: "large", weight: "semibold" }}
							token={paymentInfo?.tokenSupportEntity}
							conversionConfig={{ size: "small", direction: "row" }}
						/>
					</div>
					<div className={classes["buttonRow"]}>
						<Button variant={EButtonVariant.OUTLINED} onClick={this.openAmountToSendModal} disabled={this.state.loading}>
							{I18n.translate("buttons.back")}
						</Button>
						<Button
							variant={EButtonVariant.CONTAINED}
							color={EButtonColor.PRIMARY}
							onClick={this.confirmPayment}
							isLoading={this.state.loading}>
							{I18n.translate("buttons.confirm_payment")}
						</Button>
					</div>
				</div>
			</Modal>
		);
	}

	public override componentDidMount() {
		this.removeOnModalChange = ConfirmPaymentModalStore.getInstance().onChange(this.updateModalState);
		this.removeOnUserChange = UserStore.getInstance().onChange(this.updateUser);
		this.updateModalState(ConfirmPaymentModalStore.getInstance().modalsOpenedState, ConfirmPaymentModalStore.getInstance().getProps());
	}

	public override componentWillUnmount() {
		this.removeOnModalChange();
		this.removeOnUserChange();
	}

	private openAmountToSendModal() {
		this.close();
		assert(this.state.paymentInfo?.tokenSupportEntity, "Can not open confirm payment modal without token");
		AmountToSendModalStore.getInstance().openModal({ token: this.state.paymentInfo?.tokenSupportEntity });
	}

	private close() {
		ConfirmPaymentModalStore.getInstance().closeModal();
	}

	private updateModalState(modalsOpenedState: { [type: string]: boolean }, paymentInfo?: IConfirmPaymentModalProps) {
		this.setState({ isOpen: modalsOpenedState[EModal.CONFIRM_PAYMENT] ?? false, paymentInfo }, this.estimateGasPrice);
	}

	private updateUser(user: AppUserEntity | null) {
		this.setState({ user });
	}

	private async estimateGasPrice() {
		if (!this.state.paymentInfo) return;
		const { amountToSend, receiverAddress, tokenSupportEntity } = this.state.paymentInfo;
		const senderAddress = this.state.user?.appWallet?.userAddress;
		assert(senderAddress && receiverAddress && amountToSend && tokenSupportEntity, "Missing data in ConfirmPaymentModal");

		const enrichedErc20Contract = await Erc20ContractStore.getInstance().getEnrichedContractByAddress(tokenSupportEntity.address);

		const tx =
			enrichedErc20Contract.contractEntity.protocol === TokenProtocol.NATIVE
				? { to: receiverAddress, value: amountToSend.toString(10) }
				: await enrichedErc20Contract.ethersContract.createTransferTx(receiverAddress, amountToSend);

		if (!tx) {
			console.error("Can not create tx for payment");
			return;
		}

		const gasPrice = await enrichedErc20Contract.ethersContract.estimateGasPrice(tx);
		const nativeToken = TokenSupports.getInstance().getTokenByName(ETokenSymbol.POL);
		this.setState({ tx, gasPrice, nativeToken });
	}

	private async confirmPayment() {
		try {
			this.setState({ loading: true });
			await TransactionToast.processTransaction(() => WalletStore.getInstance().sendTransaction(this.state.tx));
			this.setState({ loading: false });
			this.close();
		} catch (e) {
			this.setState({ loading: false });
		}
	}
}
