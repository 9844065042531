export enum ETokenSymbol {
	ETH = "ETH",
	USDC = "USDC",
	AVAX = "AVAX",
	POL = "POL",
	XTZ = "XTZ",
	SOL = "SOL",
	BNB = "BNB",
	ADA = "ADA",
	BTC = "BTC",
	WETH = "WETH",
}

export enum ECurrency {
	EUR = "eur",
	USD = "usd",
	USDC = "usd-coin",
	USDT = "USDT",
}
